<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
      <div class="title">{{ $t('audit').ApplicationRecord }}</div>
    </header>
    <!-- 状态 -->
    <div class="navTab">
      <div
        class="item"
        @click="handelStatus('Wait')"
        :class="{ activeNav: transferStatus == 'Wait' }"
      >
        {{ $t('pay').Wait.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="handelStatus('Success')"
        :class="{ activeNav: transferStatus == 'Success' }"
      >
        {{ $t('pay').Success.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="handelStatus('Auto')"
        :class="{ activeNav: transferStatus == 'Auto' }"
      >
        {{ $t('pay').Auto.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="handelStatus('Fail')"
        :class="{ activeNav: transferStatus == 'Fail' }"
      >
        {{ $t('pay').Fail.toUpperCase() }}
      </div>
    </div>
    <!-- 类型 -->
    <div class="navTab_type">
      <div
        class="item"
        @click="handelType(0)"
        :class="{ activeNav: type == 0 }"
      >
        {{ $t('audit').Entire }}
      </div>
      <div
        class="item"
        @click="handelType(1)"
        :class="{ activeNav: type == 1 }"
      >
        {{ $t('audit').Invitation }}
      </div>
      <div
        class="item"
        @click="handelType(2)"
        :class="{ activeNav: type == 2 }"
      >
        {{ $t('audit').Gratitude }}
      </div>
      <div
        class="item"
        @click="handelType(3)"
        :class="{ activeNav: type == 3 }"
      >
        {{ $t('audit').Upgrade }}
      </div>
    </div>
    <!-- 列表 -->
    <div class="list" @scroll="handleScroll">
      <div
        class="li_item"
        v-for="(item, index) in list"
        :key="index"
        @click="toShowDetail(item)"
      >
        <div class="li_i_top">
          <div class="li_i_left">
            <img
              :src="
                item.CollectionHeadImg
                  ? item.CollectionHeadImg
                  : require('@/assets/newImg/direct_i_heard.png')
              "
              width="24px"
              height="24px"
            />
            <div class="li_i_t_name">
              {{
                item.CollectionUserNickName
                  ? item.CollectionUserNickName
                  : 'Platform'
              }}
            </div>
            <div class="li_i_t_star">
              {{ item.CollectionGradeType }}
              {{ $t('common').Star.toUpperCase() }}
            </div>
          </div>

          <div
            class="li_i_t_status"
            :class="
              item.TransferStatus == 'Success'
                ? 'green'
                : item.TransferStatus == 'Fail'
                ? 'red'
                : ''
            "
          >
            {{
              item.TransferStatus == 'Wait'
                ? $t('pay').Wait
                : item.TransferStatus == 'Success'
                ? $t('pay').Success
                : item.TransferStatus == 'Fail'
                ? $t('pay').Fail
                : item.TransferStatus == 'Auto'
                ? $t('pay').Auto
                : ''
            }}
          </div>
        </div>
        <div class="li_i_num">
          <div class="li_i_n_txt">{{ item.CollectionContactInformation }}</div>
          <div class="li_i_n_price"><span>$</span> {{ item.Amount }}</div>
        </div>
        <div class="li_i_line"></div>
        <div class="li_i_msg" v-if="item.TransferStatus == 'Wait'">
          {{ item.CreateTime | getLocalTime }} {{ $t('pay').Paid }}
        </div>
        <div class="li_i_msg" v-if="item.TransferStatus != 'Wait'">
          {{ item.AuditTime | getLocalTime }} {{ $t('pay').Audited }}
        </div>
      </div>
      <NoData v-if="list.length === 0" />
    </div>
    <!-- 详情弹窗 -->
    <van-dialog
      v-model="showDetail"
      :showConfirmButton="false"
      closeOnClickOverlay
    >
      <div class="dialog_upLock">
        <div class="di_title">{{ $t('audit').PaymentDetails }}</div>
        <div class="di_info">
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').UserID
              }}<span>：{{ detailInfo.CollectionUserId }}</span>
            </div>
          </div>
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').Usernickname
              }}<span>：{{ detailInfo.CollectionUserNickName }}</span>
            </div>
          </div>
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').ContactInformation
              }}<span>：{{ detailInfo.CollectionContactInformation }}</span>
            </div>
          </div>
          <!-- <div class="item">
            <div class="item_lable">
              {{ $t('audit').TransferType
              }}<span>：{{ detailInfo.TransferType }}</span>
            </div>
          </div> -->
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').PaymentAmount
              }}<span>：${{ detailInfo.Amount }}</span>
            </div>
          </div>
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').PaymentTime
              }}<span>：{{ detailInfo.CreateTime | getLocalTime }}</span>
            </div>
          </div>
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').PaymentType
              }}<span
                >：{{
                  detailInfo.PayType == 'Bank'
                    ? $t('pay').Bank
                    : detailInfo.PayType == 'TRC20'
                    ? $t('pay').TRC20
                    : $t('pay').QRCode
                }}</span
              >
            </div>
          </div>
          <!-- 收款信息 -->
          <div
            class="item"
            v-if="toJsonPase(detailInfo.CollectionAddress).CollectionName"
          >
            <div class="item_lable">
              {{ $t('pay').Name
              }}<span
                >：{{
                  toJsonPase(detailInfo.CollectionAddress).CollectionName
                }}</span
              >
            </div>
          </div>
          <div
            class="item"
            v-if="toJsonPase(detailInfo.CollectionAddress).CollectionBank"
          >
            <div class="item_lable">
              {{ $t('pay').BankName
              }}<span
                >：{{
                  toJsonPase(detailInfo.CollectionAddress).CollectionBank
                }}</span
              >
            </div>
          </div>
          <div
            class="item"
            v-if="toJsonPase(detailInfo.CollectionAddress).CollectionAccount"
          >
            <div class="item_lable">
              {{ $t('pay').BankAccount
              }}<span
                >：{{
                  toJsonPase(detailInfo.CollectionAddress).CollectionAccount
                }}</span
              >
            </div>
          </div>
          <div
            class="item"
            v-if="toJsonPase(detailInfo.CollectionAddress).TRC20"
          >
            <div class="item_lable">
              TRC20<span
                >：{{ toJsonPase(detailInfo.CollectionAddress).TRC20 }}</span
              >
            </div>
          </div>
          <div
            class="item"
            v-if="toJsonPase(detailInfo.CollectionAddress).QRName"
          >
            <div class="item_lable">
              {{ toJsonPase(detailInfo.CollectionAddress).QRName }}：
            </div>
            <img
              :src="toJsonPase(detailInfo.CollectionAddress).QRCode"
              @click="
                toImagePreview(toJsonPase(detailInfo.CollectionAddress).QRCode)
              "
              width="100px"
              height="100px"
            />
          </div>
          <!-- 付款图片 -->
          <div class="item">
            <div class="item_lable">{{ $t('audit').PaymentDocument }}：</div>
            <img
              :src="detailInfo.PayImg"
              @click="toImagePreview(detailInfo.PayImg)"
              width="100px"
              height="100px"
            />
          </div>
          <div class="item">
            <div class="item_lable">
              {{ $t('audit').PaymentRemark
              }}<span>：{{ detailInfo.Remark }}</span>
            </div>
          </div>
          <!-- 审核信息 -->
          <div class="item" v-if="detailInfo.TransferStatus != 'Wait'">
            <div class="item_lable">
              {{ $t('audit').AuditStatus
              }}<span
                >：{{
                  detailInfo.TransferStatus == 'Wait'
                    ? $t('pay').Wait
                    : detailInfo.TransferStatus == 'Success'
                    ? $t('pay').Success
                    : detailInfo.TransferStatus == 'Fail'
                    ? $t('pay').Fail
                    : detailInfo.TransferStatus == 'Auto'
                    ? $t('pay').Auto
                    : ''
                }}</span
              >
            </div>
          </div>
          <div class="item" v-if="detailInfo.TransferStatus != 'Wait'">
            <div class="item_lable">
              {{ $t('audit').AuditTime
              }}<span>：{{ detailInfo.AuditTime | getLocalTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import { ImagePreview } from 'vant'
import NoData from '@/components/noData'

export default {
  components: {
    NoData
  },
  data() {
    return {
      showDetail: false,
      detailInfo: { CollectionAddress: '{}' },
      transferStatus: 'Wait',
      type: 0, //类型-》 1：推荐费，2：感恩锁，3：升级
      noList: false,
      page: 1,
      size: 10,
      list: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection', 'baseMsg'])
  },
  mounted() {
    this.getMyPay()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toJsonPase(val) {
      return JSON.parse(val)
    },
    //滑动事件
    handleScroll(e) {
      if (!this.noList) {
        this.noList = true
        this.page = this.page + 1
        this.getMyPay()
      }
    },
    //详情
    toShowDetail(val) {
      this.detailInfo = val
      this.showDetail = true
    },
    //图片预览
    toImagePreview(val) {
      ImagePreview({images:[val],closeable: true})
    },
    //状态切换事件
    handelStatus(val) {
      this.transferStatus = val
      this.page = 1
      this.getMyPay()
    },
    //搜索
    handelSearch() {
      this.page = 1
      this.getMyPay()
    },
    //类型切换事件
    handelType(val) {
      this.type = val
      this.page = 1
      this.getMyPay()
    },
    // 获取我支付的订单列表
    async getMyPay() {
      var form = {
        page: this.page,
        size: this.size,
        transferStatus: this.transferStatus,
        type: this.type
      }
      const res = await userApi.getMyPay(form)
      var list = res.Data
      if (this.page == 1) {
        this.list = list
      } else {
        this.list = this.list.concat(list)
      }
      if (list.length > 0) this.noList = false
      else this.noList = true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background-image: url(../../assets/newImg/donate_bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 0 20px 0;
  color: #fff;
}
header {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 20px;
  .title {
    text-align: center;
    width: 350px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
}
.navTab {
  margin: 3px 5px;
  display: flex;
  justify-content: space-between;
  height: 45px;
  background-image: url(../../assets/newImg/regedit_tab.png);
  background-size: 100% 100%;
  padding: 0 10px;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 9px;
    color: #ffffff;
    margin: 7px 0 10px 0;
  }
  .activeNav {
    color: #9a5300;
    background-image: url(../../assets/newImg/active_btn.png);
    background-size: 100% 100%;
  }
}
.navTab_type {
  margin: 3px 5px;
  display: flex;
  justify-content: space-between;
  height: 45px;
  background-image: url(../../assets/newImg/regedit_tab.png);
  background-size: 100% 100%;
  padding: 0 10px;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 9px;
    color: #ffffff;
    margin: 7px 0 10px 0;
  }
  .activeNav {
    color: #9a5300;
    background-image: url(../../assets/newImg/active_btn.png);
    background-size: 100% 100%;
  }
}
.red {
  color: #ea0d32 !important;
}
.green {
  color: #51d292 !important;
}
.list {
  margin: 10px;
  .li_item {
    height: 134px;
    background: #ffffff;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 15px;
    .li_i_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .li_i_left {
        display: flex;
        justify-content: left;
        align-items: center;
        .li_i_t_name {
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 15px;
          color: #333333;
          margin-left: 16px;
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .li_i_t_star {
          margin-left: 16px;
          width: 39px;
          height: 17px;
          background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
          border-radius: 5px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 8px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .li_i_t_status {
        font-family: Adobe Heiti Std;
        font-weight: normal;
        font-size: 15px;
        color: #999999;
      }
    }
    .li_i_num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0 15px 40px;
      .li_i_n_txt {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 15px;
        color: #333333;
      }
      .li_i_n_price {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 21px;
        color: #f54337;
        display: flex;
        align-items: baseline;
        span {
          font-size: 13px;
        }
      }
    }
    .li_i_line {
      height: 1px;
      background: #dedede;
    }
    .li_i_msg {
      text-align: right;
      margin-top: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
    .li_i_btn {
      margin-top: 8px;
      display: flex;
      justify-content: right;
      align-items: center;
      .li_i_b_txt {
        margin-right: 10px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
      }
      .li_i_b_t {
        width: 78px;
        height: 28px;
        background: linear-gradient(0deg, #51d292 0%, #47ffa4 100%);
        border-radius: 14px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .li_i_b_f {
        width: 78px;
        height: 28px;
        background: linear-gradient(0deg, #ff2002 0%, #ff8a61 100%);
        border-radius: 14px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
::v-deep .van-dialog {
  background-color: transparent;
  width: 320px;
}
.dialog_upLock {
  width: 100%;
  .di_title {
    position: relative;
    margin: 0 30px -20px 30px;
    height: 41px;
    background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
    box-shadow: 0px 2px 4px 0px rgba(173, 61, 0, 0.75);
    border-radius: 21px;
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 18px;
    color: #8c3b00;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .di_info {
    // height: 500px;
    background: #ffffff;
    border-radius: 15px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 1px;
    .item {
      margin: 20px;
      text-align: left;
      display: flex;
      .item_lable {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        span {
          font-weight: normal;
        }
      }
    }
    .di_i_btn {
      margin: 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .li_i_b_t {
        width: 135px;
        height: 39px;
        background: linear-gradient(0deg, #51d292 0%, #47ffa4 100%);
        border-radius: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 16px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .li_i_b_f {
        width: 135px;
        height: 39px;
        background: linear-gradient(0deg, #ff2002 0%, #ff8a61 100%);
        border-radius: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 16px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  ::v-deep .van-uploader__upload-icon {
    color: #492fb6;
    font-size: 24px;
  }
}
</style>
